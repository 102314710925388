<template>
  <section>
    <search-bar-register
      class="mt-2 mb-2 sm:mb-3"
      :title="$t('sectionPharmaceuticals.searchPharmaceuticals')"
      @input="filter = $event"
    />
    <b-table :fields="fields" 
             :items="items" 
             responsive="sm" 
             :filter="filter"
             :perPage="itemsPerPage"
             show-empty
            empty-text="Não existem Formas farmacêuticas cadastradas"
            :empty-filtered-text="`Forma farmacêutica não encontrado`"
             >
      <template #cell(forma_farmacêutica)="data">
        {{ Indice + (data.index + 1) }}
      </template>
      <template #cell(ações)="data">
        <div class="acoes">
          <div>
            <button @click="RedirectUrl(`view/${data.item.id}`)">
              <EyeIcon class="w-5 mr-2 icon__actions" />
            </button>
          </div>
          <div>
            <button @click="RedirectUrl(`edit/${data.item.id}`)">
              <EditIcon class="w-4 mr-2 icon__actions" />
            </button>
          </div>
          <div>
            <button @click="deletarFarmacecutica(data.item.id)">
              <DeleteIcon class="w-5 mr-2 icon__actions" />
            </button>
          </div>
        </div>
      </template>
    </b-table>

    <footer class="mt-2 flex items-center">
      <Pagination
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        @input="ListaFormaFarmaceutica"
        text="formas farmacêuticas"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      :closeModal="closeModal"
      :buttonOptions="true"
      :confirmarDelete="confirmarDelete"
      :DeleteId="deletar"
      :modalText="$t('commons.modalTextDelete')"
    />
    <Modal
      v-if="modalDeleted"
      :modalIsOpen="modalDeleted"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextDeleted')"
    />
  </section>
</template>

<script>
import { BTable } from "bootstrap-vue";

import SearchBarRegister from "@core/components/register/SearchBarRegister.vue";
import PageOperationsEnum from "@core/helpers/constants/pagesOperation";
import Modal from "@core/components/commons/modal/Modal.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";

import EyeIcon from "@/assets/img/eye.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
  name: "ListPharmaceuticals",
  components: {
    SearchBarRegister,
    Modal,
    Pagination,
    BTable,
    EyeIcon,
    EditIcon,
    DeleteIcon,
  },
  data() {
    return {
      filter:null,
      modalIsOpen: false,
      modalDeleted:false,
      deletar: null,
      totalItems: 0,
      itemsPerPage: 10,
      currentPage: 1,
      PageOperationsEnum,
      fields: [
        { key: "forma_farmacêutica", sortable: true },
        { key: "descricao",label:"Descrição", sortable: true },
        { key: "tipoForma",label:"Tipo" ,sortable: true },
        { key: "tipoUso", label:"Uso",sortable: true },
        { key: "pop", label:"P.O.P",sortable: false },
        { key: "ações" },
      ],
      items: [],
    };
  },
  created() {
    this.ListaFormaFarmaceutica()
  },
  methods: {
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalDeleted = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    ListaFormaFarmaceutica(page = 1) {
      this.$http({
        url: `/formaFarmaceutica/obter-grid/${page}`,
        method: "Get",
      }).then((response) => {
        this.items = response.data.itens;
        this.totalItems = response.data.total;
        this.itemsPerPage = response.data.itensPorPagina;
        this.Indice = (page * response.data.itensPorPagina) - response.data.itensPorPagina;
      });
    },
    RedirectUrl(val) {
      this.$router.push(`farmaceuticas/${val}`);
    },
    deletarFarmacecutica(id) {
      this.modalIsOpen = true;
      this.deletar = id;
    },
    confirmarDelete(id) {
      this.$http({
        url: `/formaFarmaceutica/remover/${id}`,
        method: "Post",
      }).then(() => {
        this.modalIsOpen = false;
        this.modalDeleted = true;
        setTimeout(() => this.$router.go(), 2000);
      });
    },
  },
};
</script>

<style lang="scss">
.acoes {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
</style>
