import HeaderRegister from "@core/components/register/HeaderRegister.vue";

import ListPharmaceuticals from "@/components/farmaceuticas/ListPharmaceuticals.vue";

export default {
  components: {
    HeaderRegister,
    ListPharmaceuticals,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
